/* jshint esnext: true */
import {APP_NAME, $document, isDebug} from '../utils/environment';
import AbstractModule from './AbstractModule';
import {initGmap} from './../utils/gmap'

const MODULE_NAME     = 'Form';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const CLASS = {
    NOT_VALID: '-error'
};

const SELECTOR = {
    STATE: '.js-state',
    COUNTRY: '.js-country',
    ZIP: '.js-zip'
};

export const EVENT = {
    CLICK:   `click.${EVENT_NAMESPACE}`,
    FOCUSIN: `focusin.${EVENT_NAMESPACE}`,
    INPUT:   `change.${EVENT_NAMESPACE}, input.${EVENT_NAMESPACE}, blur.${EVENT_NAMESPACE}`,
    SUBMIT:  `submit.${EVENT_NAMESPACE}`,
    PAUSE:   `pause.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        // Declaration of properties
        // console.log('🔨 [module]:constructor - Form');

        this.isTransmitting = false;
        this.contentType    = 'application/x-www-form-urlencoded; charset=UTF-8';
        this.processData    = true;
        this.usingFormdata  = false;
        this.$form          = this.$el;
        this.message        = '';

        this.captchaId      = null;
        this.$captcha       = $('.js-captcha', this.$el);

        this.$loader = this.$form.find('.js-loader');
        this.$info   = this.$form.find('.js-form-info');
        this.$submit = this.$form.find('[type="submit"]');

        this.isPaused = false;
        this.resume = undefined;

        this.$zip = this.$el.find(SELECTOR.ZIP);
        this.$state = this.$el.find(SELECTOR.STATE);
        this.$country = this.$el.find(SELECTOR.COUNTRY);
    }

    init() {
        // Set events and such
        this.$inputs = this.$el.find(':input');
        this.$inputs.on(EVENT.FOCUSIN, (e) => this.onFocusin(e));
        this.$inputs.on(EVENT.INPUT, (e) => this.onInput(e));
        $document.on(EVENT.PAUSE, (e) => {
            this.isPaused = e.flag;

            if (!e.flag && (typeof this.resume === 'function')) {
                this.resume();
                this.resume = undefined;
            }
        });
        this.$el.on(EVENT.SUBMIT, (e) => this.onSubmit(e));
    }

    /**
     * Handles the form submission event
     * @param e
     * @return {void}
     */
    onSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        this.$info.text('');

        if (!this.validateForm(e)) {
            // Set the error message.
            this.handleFeedback('error');

            return false;
        }

        this.toggleLoading(true);

        // Captcha
        if (!isDebug && this.captchaId === null && this.$captcha.length > 0) {
            const id       = this.$captcha.html('').get(0).getAttribute('id');
            this.captchaId = window.grecaptcha.render(id, {
                sitekey:  window.recaptchaKey,
                size:     'invisible',
                callback: (token) => {
                    $('.js-captcha-response', this.$form).val(token);
                    // this.$el.addClass('has-loaded-captcha');
                    this.verifyZipCodeBeforePosting(() => { this.sendData() });
                }
            });
            window.grecaptcha.execute(this.captchaId);
        }


        else {
            this.verifyZipCodeBeforePosting(() => { this.sendData() });
        }
    }

    /**
     * Verifies the zip code value (valid state or not) before posting.
     * Sets the error state when geocoding fails.
     * Sets the state on the state form property when valid.
     * @param callback
     */
    verifyZipCodeBeforePosting(callback)
    {
        if (this.$country.val() === 'USA') {
            // Set events and such
            if (typeof google === 'undefined') {
                // If google is undefined,
                initGmap(() => { this.verifyZipCodeBeforePosting(callback) });

                return false;
            }
            this.$geocoder = new google.maps.Geocoder();

            let zip = this.$zip.val();
            let country = this.$country.val();

            $document.trigger({
                type: EVENT.PAUSE,
                flag: true
            });

            this.$geocoder.geocode({'address': zip + ' ' + country}, (results, status) => {
                if (status === 'OK') {
                    let state = '';
                    let components = results[0].address_components;
                    for (let c in components) {
                        let type = components[c].types[0];
                        if (type === 'administrative_area_level_1') {
                            state = components[c].short_name;
                            break;
                        }
                    }

                    if (!state) {
                        this.$el.parents('.o-scroll').triggerHandler({
                            type:    'scrollTo.LocomotiveScroll',
                            options: {targetElem: this.$zip, targetOffset: -100}
                        });

                        this.$zip.focus();
                        this.setInputError(this.$zip, true);
                        this.handleFeedback('error');
                        this.toggleLoading(false);

                    } else {
                        this.$state.val(state);
                        callback();
                    }
                } else {

                    this.$el.parents('.o-scroll').triggerHandler({
                        type:    'scrollTo.LocomotiveScroll',
                        options: {targetElem: this.$zip, targetOffset: -100}
                    });

                    this.setInputError(this.$zip, true);
                    this.$zip.focus();
                    this.handleFeedback('error');
                    this.toggleLoading(false);
                }

                $document.trigger({
                    type: EVENT.PAUSE,
                    flag: false
                });
            });

            return this;
        }

        callback();
        return this;
    }

    /**
     * @param e
     * @return {void}
     */
    onFocusin(e) {
        let $ele = $(e.currentTarget);
        $ele.data('val', $ele.val());
    }

    /**
     * Event triggered on fields input.
     *
     * @param e
     * @return {void}
     */
    onInput(e) {
        let $ele = $(e.currentTarget);
        if ($ele.data('val') === $ele.val()) return;

        this.validateField(e.currentTarget);

        if (this.$form.find('-error').length) {
            this.$info.text('');
        }
    }

    /**
     * Send all data present in the form to the form action endpoint.
     *
     * @return {void}
     */
    sendData() {
        if (!this.isTransmitting) {
            if (this.isPaused) {
                this.resume = this.sendData;
                return;
            }

            this.isTransmitting = true;

            const data = (this.usingFormdata) ? new FormData(this.$form.get(0)) : this.$form.serialize();

            setTimeout(() => {
                $.ajax({
                    url:         (this.$form.prop('action')),
                    method:      (this.$form.prop('method') || 'POST'),
                    contentType: this.contentType,
                    processData: this.processData,
                    dataType:    'json',
                    data:        data
                })
                    .done($.proxy(this.onAjaxDone, this))
                    .fail((response, textStatus) => {
                        if (typeof response.responseJSON.errors !== 'undefined' && response.responseJSON.errors.length > 0) {
                            this.manageErrors(response.responseJSON.errors);
                        } else {
                            console.group(`Something went wrong with the request (fail, ${textStatus})`);
                            console.log(response);
                            console.log(textStatus);
                            console.groupEnd();
                        }
                    })
                    .always(() => {
                        setTimeout(() => {
                            this.isTransmitting = false;
                            this.toggleLoading(false);
                        }, 200);
                    });
            }, 200);
        }
    }

    /**
     * Manage the `.done()` callback for the form transfer.
     *
     * @return {void}
     */
    onAjaxDone(response, textStatus) {
        if (response.download) {
            window.location = response.download;
        }

        if (response.success === true) {
            if (response.feedback) {
                this.handleFeedback('custom', response.feedback);
            } else {
                this.handleFeedback('success');
            }
            // if (this.$preSubmit.length > 0 && this.$postSubmit.length > 0) {
            //     this.$preSubmit.fadeOut(() => {
            //         this.$postSubmit.fadeIn();
            //     });
            // }
        } else {
            console.warn(`Something went wrong with the request (done, ${textStatus})`);
        }
    }

    /**
     *
     * @param e
     * @return {boolean}
     */
    validateForm(e) {
        let valid       = true, // Assume its valid first
            $firstField = null;

        // Parse all fields
        for (let i = 0; i < e.target.length; i++) {
            if (!this.validateField(e.target[i])) {
                valid = false; // Set the form to invalid if one field is not valid
                $firstField = $firstField || $(e.target[i]);
            }
        }
        if (this.$country.val() === 'USA') {
            // Regex from https://stackoverflow.com/questions/160550/zip-code-us-postal-code-validation
            let $zip = this.$el.find('[name=zip]');
            if ($zip.val()) {
                if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test($zip.val())) {
                    valid       = false; // Set the form to invalid if one field is not valid
                    $firstField = $firstField || $zip;
                }
            }
        }

        if (!valid) {
            this.$el.parents('.o-scroll').triggerHandler({
                type:    'scrollTo.LocomotiveScroll',
                options: {targetElem: $firstField, targetOffset: -100}
            });

            $firstField.focus();
            this.setInputError($firstField, true);
            return false;
        }

        return true;
    }

    /**
     * Validate an input with js validation.
     * @param el
     * @return {boolean}
     */
    validateField(el) {
        let $el = $(el);

        if ($el.prop('required')) {
            let value    = this.$form.find('[name="' + el.name + '"]').val();
            let response = value ? value.length > 0 : false;
            for (let item of Array.from(this.$form.find('[name="' + el.name + '"]'))) {
                if (item.checked) response = true
            }

            this.setInputError($el, !response);
            return response;
        } else if (el.hasAttribute('data-is-required') && el.type == "checkbox" && !el.checked) {
            this.setInputError($el, true);
            return false;
        } else if (el.hasAttribute('data-is-required') && (!el.value || el.validity.typeMismatch)) {
            this.setInputError($el, true);
            return false;
        } else {
            this.setInputError($el, false);
            return true;
        }
    }

    /**
     * @param flag
     */
    toggleLoading(flag = null) {
        if (flag) {
            this.$loader.toggleClass('-inactive', !flag);
            this.$submit.toggleClass('-disabled', flag);
        } else {
            this.$loader.toggleClass('-inactive');
            this.$submit.toggleClass('-disabled');
        }
    }

    /**
     * Set the error state of an input.
     * @param $el
     * @param {boolean} state
     */
    setInputError($el, state) {
        if ($el.attr('type') === 'file') {
            $el.parent('.o-file-wrap').toggleClass(CLASS.NOT_VALID, state);
        } else {
            $el.toggleClass(CLASS.NOT_VALID, state);
        }

        return state;
    }

    /**
     * Manage errors sent by back end on front end and reset captcha.
     *
     * @param  {array}  errors  List of error objects
     * @return void
     */
    manageErrors(errors) {
        let i     = 0;
        const len = errors.length;

        // Set the error message.
        this.handleFeedback('error');

        for (; i < len; i++) {
            // const $input = $('#' + errors[i].property);
            const $input = $(`[name="${errors[i].property}"]`);
            this.setInputError($input, true);

            if (i === 0) {
                // slide to top.
                this.$el.parents('.o-scroll').triggerHandler({
                    type:    'scrollTo.LocomotiveScroll',
                    options: {targetElem: this.$el}
                });

                $input.focus();
            }
        }
    }

    /**
     * Either set an existing message or a custom one.
     * @param message
     */
    handleFeedback(message, $feedback = null) {
        switch (message) {
            case 'custom':
                if ($feedback) {
                    this.$info.text($feedback);
                    this.$info.toggleClass('-negative', false);
                    break;
                }
            case 'success':
                this.$info.text('Your message was successfully sent ! We will get back to you shortly');
                this.$info.toggleClass('-negative', false);
                break;
            case 'error':
                this.$info.text('An error occurred, please make sure all the required fields are properly filled');
                this.$info.toggleClass('-negative', true);
                break;
            default:
                this.$info.text(message);
        }
    }

    destroy() {
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
    }
}
