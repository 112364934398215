/* jshint esnext: true */
import { APP_NAME, $document } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'ProductCTAs';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`,
    END_OF_PAGE: `endOfPage.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        // Declaration of properties
        // console.log('🔨 [module]:constructor - ProductCTAs');
        this.$toggle = this.$el.find('.js-toggle')
    }

    init() {
        // Set events and such

        this.$toggle.on(EVENT.CLICK, () => {
            this.$el.toggleClass('-opened')
        })

        this.onEndOfPage = (e) => {
            this.$el.toggleClass('-opened', false)
            this.$el.toggleClass('-hidden', e.way == 'enter')
        }
        $document.on(EVENT.END_OF_PAGE, this.onEndOfPage)
    }

    destroy() {
        // console.log('❌ [module]:destroy - ProductCTAs');
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
        this.$toggle.off(`.${EVENT_NAMESPACE}`);
        $document.off(EVENT.END_OF_PAGE, this.onEndOfPage)
    }
}
