/* jshint esnext: true */
import { APP_NAME, $body, $document } from '../utils/environment';
import { DATABASE } from '../app'

import AbstractModule from './AbstractModule';
import { EVENT as MATERIALS_PANEL_EVENT } from './MaterialsPanel'
import ProductQuote, { EVENT as MODAL_EVENT } from './ProductQuote'
import Viewer, { EVENT as VIEWER_EVENT } from './Viewer'
import { EVENT as LOADER_EVENT } from './Loader'

const MODULE_NAME = 'ProductCustomizer';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

export const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`,
    TAP: `click.${EVENT_NAMESPACE}`,
    RESET: `reset.${EVENT_NAMESPACE}`,
    CHANGE: `change.${EVENT_NAMESPACE}`,
    OPENED_PANEL: `opened_panel.${EVENT_NAMESPACE}`,
    CLOSED_PANEL: `closed_panel.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);
        this.options = options

        console.log('🔨 ['+MODULE_NAME+']:constructor - Example');

        // Declaration of properties
        this.$view = this.$el.find('.c-product-customizer_viewer')
        this.$settings = this.$el.find('.c-product-settings')
        this.$settingsToggler = this.$el.find('.c-product-settings_toggler')
        this.$settingsList = this.$el.find('.c-product-settings_list')

        // VIEWER
        this.$viewer = this.$el.find('.js-viewer')
        this.viewerModule = new Viewer({ $el: this.$viewer, el: this.$viewer[0], productId: this.options['product-id'] })

        // MODALE
        this.$modal = this.$el.find('.js-modal')
        this.productQuoteModule = new ProductQuote({ $el: this.$modal, el: this.$modal[0], productId: this.options['product-id'] })

        this.$form = this.$settings.find('form')
        this.form = this.$form[0]

        this.$fields = this.$form.find('input,select')

        this.$materialPickers = this.$settingsList.find('.o-material-picker')

        this.$openFormBtn = this.$el.find('.js-open-form')
        this.$resetBtn = this.$el.find('.js-reset')

        this.setDefaults()
    }

    init() {

        this.viewerModule.init()
        this.productQuoteModule.init()

        // requestAnimationFrame(this.openPanel.bind(this))

        // SETTINGS PANE TOGGLER
        this.settingsClickBind = () => {
            this.togglePanel()
        }
        this.$settingsToggler.on(EVENT.CLICK, this.settingsClickBind)

        // MATERIALS PICKER
        this.materialPickerTap = (e) => {
            let el = $(e.target).parents('.o-material-picker')[0] || e.target
            let $el = $(el)

            this.$materialPickers.toggleClass('-active', false)
            $el.toggleClass('-active', true)

            $document.triggerHandler({
                type: MATERIALS_PANEL_EVENT.PICKER_CLICK,
                options: {
                    el
                }
            });
        }
        this.materialPickersListeners = []
        this.$materialPickers.each((i,el) => {
            this.materialPickersListeners.push(new Hammer(el).on('tap', this.materialPickerTap))
        })

        this.viewerDoubleTap = new Hammer(this.viewerModule.el).on('doubletap', this.closePanel.bind(this))

        this.$settingsList.on(EVENT.CLICK, (e) => {
            if(!$(e.target).parents('.o-material-picker').length && !$(e.target).hasClass('o-material-picker')) {
                $document.trigger(MATERIALS_PANEL_EVENT.CLOSE_PANEL);
            }
        })

        this.$openFormBtn.on(EVENT.CLICK, (e) => {
            e.preventDefault();
            e.stopPropagation();

            $document.triggerHandler({
                type: MODAL_EVENT.OPEN,
                options: {
                    id: 'product-quote',
                    productId: this.options['product-id']
                }
            });
        })

        this.$resetBtn.on(EVENT.CLICK, this.reset.bind(this))

        this.$fields.on(EVENT.CHANGE, (e) => {
            let el = e.currentTarget
            let $el = $(el)

            let slug = $el.attr('name')
            let value, name

            if($el.parents('.o-material-picker').length) {
                let $mp = $el.parents('.o-material-picker')

                $mp.find('> span').text(DATABASE.materials[e.currentTarget.value].label)
                if(DATABASE.materials[e.currentTarget.value].type == "texture") {
                    $mp.find('.o-material-sample_content')
                    .css('background-image', 'url('+DATABASE.spritesheet['url']+')')
                    .css('background-size', 'calc(100% * '+DATABASE.spritesheet['cols']+')')
                    .css('background-position', 'calc(-100% * '+DATABASE.materials[e.currentTarget.value].thumbnail+') calc(-100% * '+Math.floor(DATABASE.materials[e.currentTarget.value].thumbnail/DATABASE.spritesheet['cols'])+')')
                } else {
                    $mp.find('.o-material-sample_content')
                    .css('background-color', '#'+DATABASE.materials[e.currentTarget.value].value)
                    .css('background-image', '')
                    .css('background-size', DATABASE.materials[e.currentTarget.value].thumbnailSize || '')
                }

                value = $el.val()
            } else if(el.type == "checkbox") {
                value = el.checked
            } else {
                value = $el.val()
            }

            $document.triggerHandler({
                type: VIEWER_EVENT.CHANGE,
                options: { slug, value, el, name }
            });
        })

        $document.on(LOADER_EVENT.COMPLETED, (e) => {
            if(e.options.id == 'customizer') {
                setTimeout(this.openPanel.bind(this), 1250);
            }
        })

    }

    setDefaults() {
        this.defaults = {}

        for(let i = 0; i < this.form.length; i++) {
            if(this.form[i].type == "checkbox") {
                this.defaults[this.form[i].id] = this.form[i].checked
            } else {
                this.defaults[this.form[i].id] = this.form[i].value
            }
        }
    }

    reset() {
        // $document.trigger(MATERIALS_PANEL_EVENT.CLOSE_PANEL);

        for(let i = 0; i < this.form.length; i++) {
            if(this.form[i].type == "checkbox") {
                this.form[i].checked = this.defaults[this.form[i].id]
            } else if($(this.form[i]).parents('.o-material-picker').length) {
                // let $materialPicker = $(this.form[i]).parents('.o-material-picker');
                // console.log($materialPicker);

                this.form[i].value = this.defaults[this.form[i].id]
            } else {
                this.form[i].value = this.defaults[this.form[i].id]
            }

            $(this.form[i]).change()
        }

        // Inform other modules that we just reset
        $document.trigger(EVENT.RESET)
    }

    togglePanel() {
        if($body.hasClass('customizer-settings-opened')) this.closePanel()
        else this.openPanel()
    }

    openPanel() {
        $body.toggleClass('customizer-settings-opened', true)
        $document.trigger(EVENT.OPENED_PANEL)
    }

    closePanel() {
        $body.toggleClass('customizer-settings-opened', false)
        $document.trigger(EVENT.CLOSED_PANEL)

        $document.trigger(MATERIALS_PANEL_EVENT.CLOSE_PANEL);
    }

    destroy() {
        // console.log('❌ ['+MODULE_NAME+']:destroy - Example');
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
        this.$settingsToggler.off(`.${EVENT_NAMESPACE}`)
        this.$openFormBtn.off(`.${EVENT_NAMESPACE}`)
        this.$fields.off(`.${EVENT_NAMESPACE}`)
        $document.off(`.${EVENT_NAMESPACE}`)

        this.closePanel()

        for(let item of this.materialPickersListeners) {
            item.destroy()
        }

        this.viewerDoubleTap.destroy()

        this.viewerModule.destroy()
        this.productQuoteModule.destroy()
    }
}
