/* jshint esnext: true */
import { APP_NAME, $document } from '../utils/environment';
import AbstractModule from './AbstractModule';
import LocomotiveScroll from './LocomotiveScrollManager';

const MODULE_NAME = 'Scroll';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);
    }

    init() {
         this.scroll = new LocomotiveScroll({
            container: this.$el,
            selector: '.js-animate',
            smooth: true,
            mobile: false,
            mobileContainer: $document
        });

    }

    destroy() {
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
    }
}
