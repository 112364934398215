/* jshint esnext: true */
import { APP_NAME, $document } from '../utils/environment';
import { isIOS } from '../utils/is'
import AbstractModule from './AbstractModule';
import { DATABASE } from '../app'
import { EVENT as CUSTOMIZER_EVENT } from './ProductCustomizer'
import { EVENT as LOADER_EVENT } from './Loader'

import * as dat from 'dat.gui';

const MODULE_NAME = 'Viewer';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

export const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`,
    CHANGE: `fieldChange.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        // Declaration of properties
        console.log('🔨 ['+MODULE_NAME+']:constructor - Viewer');

        window.viewer = this

        this.id = options.productId
    }

    // ==========================================================================
    // INIT
    // ==========================================================================
    init() {
        // setTimeout(() => {
            console.log('🔨 ['+MODULE_NAME+']:init');
            this.initThree()
            this.initEvents()

            this.loadAssets()
            .then(() => {
                console.log('INIT EVERYTHING');

                this.initScene()
                this.initLights()
                this.setDefaultConfig()
                this.animate()
            })
        // }, 1000);
    }

    initThree() {
        this.scene,
        this.camera,
        this.renderer,
        this.element;

        this.scene = new THREE.Scene();

        // CAMERA
        // ==========================================================================
        this.target = new THREE.Vector3(0,100,0);
        this.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 20000);
        // this.camera = new THREE.OrthographicCamera( window.innerWidth / - 2, window.innerWidth / 2, window.innerHeight / 2, window.innerHeight / - 2, 1, 1000000 );
        this.camera.position.set(-200, 670, 400);
        this.camera.lookAt(this.target);
        this.scene.add(this.camera);

        // MOUSE
        // ==========================================================================
        this.mouse = new THREE.Vector2();
        this.mouse.x = - window.innerWidth / 2;
        this.mouse.y = - window.innerHeight / 2;

        // RENDERER
        // ==========================================================================
        this.renderer = new THREE.WebGLRenderer({ antialias: (isIOS() || window.devicePixelRatio > 1) ? false : true, alpha:true});
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        // this.renderer.shadowMap.needsUpdate = true
        this.element = this.renderer.domElement;
        this.container = this.el;

        this.element.width = this.container.clientWidth;
        this.element.height = this.container.clientHeight;
        this.renderer.setSize( this.container.clientWidth, this.container.clientHeight );

        // console.log(this.element.width);

        this.container.appendChild(this.element);

        // CONTROLS
        // ==========================================================================
        this.controls = new THREE.OrbitControls( this.camera, this.element);
        this.controls.target.set(0,100,0);
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 0.2;
        this.controls.rotateSpeed = 0.25;
        this.controls.enablePan = false;

        this.settings = {
            distance: {
                default: {
                    min: 410,
                    max: 670
                },
                openedPanel: {
                    min: 670,
                    max: 670
                }
            }
        }

        this.controls.minPolarAngle = 1.25
        this.controls.maxPolarAngle = 1.6

        this.controls.minDistance = this.settings.distance.default.min
        this.controls.maxDistance = this.settings.distance.default.max

        this.datGUI = new dat.GUI()
        $(this.datGUI.domElement).hide()
        this.datGUICamera = this.datGUI.addFolder('Camera')
        this.datGUICamera.add(this.controls, 'minDistance', 0, 1000)
        this.datGUICamera.add(this.controls, 'maxDistance', 0, 1000)
        this.datGUICamera.add(this.controls, 'minPolarAngle', 0, Math.PI).step(0.01)
        this.datGUICamera.add(this.controls, 'maxPolarAngle', 0, Math.PI).step(0.01)

    }

    initScene() {

        // Set the Materials
        // ==========================================================================
        // This texture will be cloned by all parts of the object
        this.defaultMaterial = new THREE.MeshPhongMaterial({
            color: 0xcccccc,
            shininess: 0,
            specular: 0xffffff
        });

        // Floor material of our scene where the shadows will be casted
        this.floorMaterial = new THREE.ShadowMaterial({
            opacity: 0.05,
        });
        this.floorMaterial.receiveShadow = true;

        // Material for the glass parts of our object
        this.glassMaterial = new THREE.MeshPhongMaterial({
            color: 0x41a2f4,
            transparent: true,
            opacity: 0.1,
            specular: 0xffffff,
            reflectivity: 1
        });

        //Light Material
        this.lightMaterial = new THREE.MeshPhongMaterial({
            color: 0xffffff,
            emissive: 0xaaaaaa,
            specular: 0xffffff,
            reflectivity: 1
        });

        this.metalMaterial = new THREE.MeshStandardMaterial({
            color: 0xffffff,
            specular: 0xffffff,
            roughness: 0.29,
            metalness: 1,
            envMap: this.textures['metalEnvMap'].clone(),
            envMapIntensity: 1
        })
        this.metalMaterial.envMap.mapping = THREE.SphericalReflectionMapping;
        this.metalMaterial.envMap.needsUpdate = true

        // Geometry
        // ==========================================================================

        // FLOOR
        let floorGeometry = new THREE.PlaneGeometry(2000,2000);
        this.floor = new THREE.Mesh(floorGeometry,this.floorMaterial);
        this.floor.rotation.set(-Math.PI/2,0,0);
        this.floor.position.set(0,0,0);
        this.floor.receiveShadow = true;
        this.scene.add(this.floor);

        // OBJECT
        this.objectWrapper = new THREE.Object3D();
        this.objectWrapper.add(this.object);

        // Set pivot point
        this.object.position.set(
            DATABASE.objects[this.id].pivot['x'],
            DATABASE.objects[this.id].pivot['z'],
            DATABASE.objects[this.id].pivot['y']
        );
        // helper
        let box = new THREE.Mesh(new THREE.BoxGeometry(10,10,10),new THREE.MeshLambertMaterial({color: 0xff0000}));
        this.objectWrapper.add(box);

        // scale down the object to 10% to gain reasonable coordinates
        this.object.scale.set(.1,.1,.1)

        // OBJECT PARTS (CHILDREN)
        for (let i = 0; i < this.object.children.length; i++) {
            this.object.children[i].material = this.defaultMaterial.clone();
            this.object.children[i].castShadow = true;
            this.object.children[i].receiveShadow = true;
        }

        // Set object glasses materials
        let glassesMeshs = DATABASE.objects[this.id].glasses.meshs;
        let i = glassesMeshs.length - 1;
        for (i; i >= 0; i--) {
            this.object.children[glassesMeshs[i]].material = this.glassMaterial;
            this.object.children[glassesMeshs[i]].castShadow = false;
            this.object.children[glassesMeshs[i]].renderOrder = true;
        }

        // Set object lights materials
        let lightMeshs = DATABASE.objects[this.id].lights.meshs;
        let j = lightMeshs.length - 1;
        for (j; j >= 0; j--) {
            this.object.children[lightMeshs[j]].material = this.lightMaterial;
            this.object.children[lightMeshs[j]].material.needsUpdate = true
        }

        // let sphere = new THREE.Mesh(new THREE.SphereGeometry( 96, 96, 96 ))
        // sphere.material = this.metalMaterial;

        // this.objectWrapper.add(sphere)

        let metalMeshs = DATABASE.objects[this.id]['table-base'].meshs;
        let k = metalMeshs.length - 1;
        for(k; k >= 0; k--) {
            this.object.children[metalMeshs[k]].material = this.metalMaterial
            this.object.children[metalMeshs[k]].material.needsUpdate = true
        }


        // Everything is set up : add the wrapper
        this.scene.add(this.objectWrapper);
    }

    initLights(){

        // SPOTLIGHT
        // ==========================================================================
        let spotLight = new THREE.DirectionalLight( 0xaaaaaa, .51);
        spotLight.position.set(-400,400,200);
        spotLight.castShadow = true;

        this.datGUISpotLight = this.datGUI.addFolder('DirectionalLight')
        this.datGUISpotLight.add(spotLight, 'intensity', 0, 1).step(0.01)
        this.datGUISpotLight.add(spotLight.position, 'x', 0, 1000)
        this.datGUISpotLight.add(spotLight.position, 'y', 0, 1000)
        this.datGUISpotLight.add(spotLight.position, 'z', 0, 1000)

        // let helper = new THREE.SpotLightHelper( light2, 0xff0000 );
        // this.scene.add( helper );

        let pointLight = new THREE.PointLight( 0xffffff, 1, 1000 );
        pointLight.position.set(0,191,0);
        pointLight.decay = 0
        pointLight.intensity = 0.15
        // pointLight.castShadow = true;
        this.scene.add( pointLight );

        this.datGUIPointLight = this.datGUI.addFolder('PointLight')
        // this.datGUIPointLight.add(pointLight, 'angle', 0, Math.PI).step(0.01)
        // this.datGUIPointLight.add(pointLight, 'penumbra', 0, 1).step(0.01)
        this.datGUIPointLight.add(pointLight, 'decay', 0, 10)
        this.datGUIPointLight.add(pointLight, 'distance', 0, 20).step(0.01)
        this.datGUIPointLight.add(pointLight, 'intensity', 0, 1).step(0.01)
        this.datGUIPointLight.add(pointLight.position, 'y', 0, 400)


        //Set up shadow properties for the light
        spotLight.shadow.mapSize.width = 2048;
        spotLight.shadow.mapSize.height = 2048;
        spotLight.shadow.camera.near = 1;    // default
        spotLight.shadow.camera.far = 10000;     // default
        spotLight.shadow.bias = -0.0001;
        // spotLight.shadow.radius = 4;

        let d = -300;
        spotLight.shadow.camera.left = -d;
        spotLight.shadow.camera.right = d;
        spotLight.shadow.camera.top = d;
        spotLight.shadow.camera.bottom = -d;

        //Create a helper for the shadow camera (optional)
        // let helper = new THREE.CameraHelper( spotLight.shadow.camera );
        // this.scene.add( helper );

        this.camera.add( spotLight );

        // AMBIAMT LIGHT
        // ==========================================================================

        var ambiantLight = new THREE.AmbientLight( 0xcccccc, .86 ); // soft white light
        this.scene.add( ambiantLight );

        this.datGUIAmbiant = this.datGUI.addFolder('AmbiantLight')
        this.datGUIAmbiant.add(ambiantLight, 'intensity', 0, 1).step(0.01)
    }

    // ==========================================================================
    // EVENTS MANAGEMENT
    // ==========================================================================
    initEvents() {

        $document.on(EVENT.CHANGE, (e) => {
            let slug = e.options.slug
            let value = e.options.value

            let fieldData = DATABASE.objects[this.id][slug]
            if(!fieldData) return

            let materialData = DATABASE.materials[value]

            if(fieldData.type == "material-picker") {
                for(let index of fieldData.meshs) {
                    let mesh = this.object.children[index]

                    // RESET
                    mesh.material.shininess = 0
                    mesh.material.bumpMap = null
                    mesh.material.bumpScale = 1

                    // TEXTURE
                    if(this.textures[value]) {
                        mesh.material.color = null

                        // Remove old texture
                        if(mesh.material.map)
                            mesh.material.map.dispose()

                        // Set new texture
                        mesh.material.map = this.textures[value].clone()
                        mesh.material.map.needsUpdate = true
                        mesh.material.map.wrapS = THREE.RepeatWrapping;
                        mesh.material.map.wrapT = THREE.RepeatWrapping;

                        // Texture size management
                        if(fieldData.settings && fieldData.settings.texture && fieldData.settings.texture.repeat)
                            mesh.material.map.repeat.set( fieldData.settings.texture.repeat, fieldData.settings.texture.repeat)
                        else mesh.material.map.repeat.set( 5, 5 );

                        // Material settings
                        if(materialData.settings) {
                            // MATERIAL PROPERTIES
                            if(materialData.settings.materialProperties) {
                                // Loop over all of the specified properties
                                for(let propertyName of Object.keys(materialData.settings.materialProperties)) {
                                    mesh.material[propertyName] = materialData.settings.materialProperties[propertyName]
                                }
                            }

                            if(materialData.settings.bump) {
                                let bump
                                if(materialData.settings.bump.map) bump = this.textures[value+'_bump'].clone()
                                else if(materialData.settings.bump.mapName) bump = this.textures[materialData.settings.bump.mapName].clone()
                                else bump = this.textures['defaultBumpMap'].clone()

                                mesh.material.bumpMap = bump
                                mesh.material.bumpMap.wrapS = THREE.RepeatWrapping;
                                mesh.material.bumpMap.wrapT = THREE.RepeatWrapping;

                                // Bump settings
                                if(typeof materialData.settings.bump.scale !== 'undefined') mesh.material.bumpScale = materialData.settings.bump.scale
                                if(fieldData.settings && fieldData.settings.texture && fieldData.settings.texture.repeat) mesh.material.bumpMap.repeat.set(fieldData.settings.texture.repeat, fieldData.settings.texture.repeat)
                                mesh.material.bumpMap.needsUpdate = true
                            }
                        }

                        mesh.material.needsUpdate = true
                    }
                    // COLOR
                    else {
                        mesh.material.map = null
                        mesh.material.color = new THREE.Color('#'+DATABASE.materials[value].value)

                        // Advanced color material settings
                        if(fieldData.settings && fieldData.settings.color) {
                            // MATERIAL PROPERTIES
                            if(fieldData.settings.color.materialProperties) {
                                // Loop over all of the specified properties
                                for(let propertyName of Object.keys(fieldData.settings.color.materialProperties)) {
                                    mesh.material[propertyName] = fieldData.settings.color.materialProperties[propertyName]
                                }

                                // Max shininess setting
                                if(DATABASE.materials[value].otherSettings && DATABASE.materials[value].otherSettings.maxShininess) {
                                    mesh.material.shininess = Math.min(mesh.material.shininess, DATABASE.materials[value].otherSettings.maxShininess)
                                }
                            }

                            // BUMPMAP settings
                            if(fieldData.settings.color.bump) {
                                // Default texture
                                mesh.material.bumpMap = fieldData.settings.color.bump.map ? this.textures[fieldData.settings.color.bump.map].clone() : this.textures['defaultBumpMap'].clone()
                                mesh.material.bumpMap.wrapS = THREE.RepeatWrapping;
                                mesh.material.bumpMap.wrapT = THREE.RepeatWrapping;

                                // Bump settings
                                if(fieldData.settings.color.bump.scale) mesh.material.bumpScale = fieldData.settings.color.bump.scale
                                if(fieldData.settings.color.bump.repeat) mesh.material.bumpMap.repeat.set(fieldData.settings.color.bump.repeat, fieldData.settings.color.bump.repeat)
                                mesh.material.bumpMap.needsUpdate = true
                            }
                        }

                        mesh.material.needsUpdate = true
                    }

                    if(DATABASE.materials[value].settings && DATABASE.materials[value].settings.materialProperties) {
                        // console.log(DATABASE.materials[value].materialsSettings);
                        for(let propertyName of Object.keys(DATABASE.materials[value].settings.materialProperties)) {
                            mesh.material[propertyName] = DATABASE.materials[value].settings.materialProperties[propertyName]
                            mesh.material.needsUpdate = true
                        }
                    }
                }
            } else if(fieldData['viewer-effect'] && fieldData['viewer-effect'] == "shape") {
                for(let choiceSlug of Object.keys(fieldData.choices)) {
                    let choice = fieldData.choices[choiceSlug]

                    // for(let meshId of choice.meshs) console.log(this.object.children[meshId]);
                    for(let meshId of choice.meshs) this.object.children[meshId].visible = false
                    let meshesToShow = fieldData.choices[Object.keys(fieldData.choices)[value == true ? 0 : 1]].meshs

                    // console.log(meshesToShow);
                    for(let meshId of meshesToShow) this.object.children[meshId].visible = true
                }
            }
        })

        $document.on(CUSTOMIZER_EVENT.OPENED_PANEL, () => {
            this.zoom(0, .5).then(() => {
                this.controls.minDistance = this.settings.distance.openedPanel.min
                this.controls.maxDistance = this.settings.distance.openedPanel.max
            })
        })

        $document.on(CUSTOMIZER_EVENT.CLOSED_PANEL, () => {
            this.controls.minDistance = this.settings.distance.default.min
            this.controls.maxDistance = this.settings.distance.default.max

            this.zoom(0.5, .5)
        })
    }

    setDefaultConfig() {
        for(let slug of Object.keys(DATABASE.objects[this.id])) {
            if(DATABASE.objects[this.id][slug].default) {
                $document.triggerHandler({
                    type: EVENT.CHANGE,
                    options: {
                        slug,
                        value: DATABASE.objects[this.id][slug].default
                    }
                });
            }
        }
    }

    setDistance(value) {
        // Get the ranged value
        value = Math.max(this.controls.minDistance, Math.min(this.controls.maxDistance, value))

        const newPosition = new THREE.Vector3()
        .subVectors(this.controls.object.position, this.target) // Substract the target vector because we want to be relative to it
        .normalize() // Set the length to 1 (this way we have a normalized vector giving us the direction)
        .multiply(new THREE.Vector3(value,value,value)) // Multiply the vector's length (1) by the value wanted
        .add(this.target) // We don't want the vector to be relative to this.target anymore

        // Apply the new position
        this.controls.object.position.set(newPosition.x,newPosition.y,newPosition.z)
    }

    zoom(value, duration = 0) {
        value = Math.max(0, Math.min(1, 1 - value))

        let range = this.controls.maxDistance - this.controls.minDistance
        let targetDist = value*range + this.controls.minDistance

        let object = {
            distance: this.controls.object.position.distanceTo(this.target)
        }

        return new Promise((resolve, reject) => {
            TweenMax.to(object, duration, {
                distance: targetDist,
                ease: Power2.easeOut,
                onUpdate: () => {
                    // console.log(object.distance);
                    this.setDistance(object.distance)
                },
                onComplete: resolve
            })
        })
    }

    // ==========================================================================
    // LOOP
    // ==========================================================================
    animate(){
        this.raf = requestAnimationFrame(()=>this.animate());
        this.render();
    }

    render(){
        this.resize();
        this.camera.updateProjectionMatrix();
        this.controls.update();
        this.renderer.render(this.scene, this.camera);
    }

    resize() {
        this.element.width = this.container.clientWidth;
        this.element.height = this.container.clientHeight;
        this.camera.aspect = this.container.clientWidth / this.container.clientHeight;
        this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
    }

    // ==========================================================================
    // LOADING ASSETS
    // ==========================================================================
    loadAssets() {
        this.loadingManager = new THREE.LoadingManager();
        this.loadingManager.onProgress = ( url, itemsLoaded, itemsTotal ) => {
            console.log( 'Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
        };

        $document.triggerHandler({
            type: LOADER_EVENT.PROGRESS,
            options: {
                id: 'customizer',
                value: 0.8
            }
        });

        return Promise.all([this.loadObj(),this.loadTextures()])
        .then(() => {
            if(this.toDestroy) return

            $document.triggerHandler({
                type: LOADER_EVENT.COMPLETE,
                options: {
                    id: 'customizer'
                }
            });

            // this.loadingProgress = 1
            console.log('GLOBAL LOADING FINISHED');
        })
    }

    loadObj() {
        return new Promise((resolve,reject) => {
            let objLoader = new THREE.OBJLoader(this.loadingManager);
            objLoader.load( '/assets/objects/'+this.id+'.obj', ( object ) => {
                if(this.toDestroy) return

                this.object = object;

                // Sort the children by ASC name in order to keep consistent IDs
                this.object.children.sort((a,b) => {
                    let nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    let nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                    return -1;
                    }
                    if (nameA > nameB) {
                    return 1;
                    }
                    // names must be equal
                    return 0;
                })

                // console.log(this.object);

                resolve()
            },(xhr) => {
                if ( xhr.lengthComputable ) {
                    var percentComplete = xhr.loaded / xhr.total * 100;
                    console.log('[OBJ] '+Math.round(percentComplete, 2) + '% downloaded' );
                }
            },(xhr) => {
                console.error(xhr);
            })
        })
    }

    loadTextures() {
        this.textures = {}
        let promises = []

        let textureLoader = new THREE.TextureLoader(this.loadingManager);
        for(let key of Object.keys(DATABASE.materials)) {
            let material = DATABASE.materials[key]

            if(material.type == 'texture') {
                promises.push(new Promise((resolve, reject) => {
                    this.textures[key] = textureLoader.load(material.value, resolve)
                }))

                if(material.settings && material.settings.bump && material.settings.bump.map) {
                    promises.push(new Promise((resolve, reject) => {
                        this.textures[key+'_bump'] = textureLoader.load(material.settings.bump.map, resolve)
                    }))
                }
            }
        }

        return Promise.all(promises)
    }


    // ==========================================================================
    // DESTROY
    // ==========================================================================
    destroy() {
        this.toDestroy = true

        window.cancelAnimationFrame(this.raf)

        this.scene.remove(this.objectWrapper)
        this.objectWrapper = null
        this.object = null

        // console.log('❌ [module]:destroy - Viewer');
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
        $document.off(`.${EVENT_NAMESPACE}`);

        this.datGUI.destroy()
        this.scene = null
    }
}
