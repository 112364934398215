import { APP_NAME, $html } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'Notice';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        this.id = options.id
        this.timeout = options.timeout || 0

        this.$close = this.$el.find('.js-notice-close')
    }

    init() {
        // Set events and such

        setTimeout(() => {
            if(!window.localStorage.getItem(APP_NAME+'.notice.'+this.id)) {
                this.$el.addClass('is-active')

                this.$close.on(EVENT.CLICK, () => {
                    this.$el.removeClass('is-active')
                    window.localStorage.setItem(APP_NAME+'.notice.'+this.id, 'dismissed')
                })
            }
        }, this.timeout);
    }

    destroy() {
        // console.log('❌ [module]:destroy - '+MODULE_NAME);
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
        this.$close.off(`.${EVENT_NAMESPACE}`);
    }
}
