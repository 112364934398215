/* jshint esnext: true */
import { APP_NAME, $document } from '../utils/environment';
import AbstractModule from './AbstractModule';
import { EVENT as SHARE_MODAL_EVENT } from './ShareModal'

const MODULE_NAME = 'ShareToggler';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);
    }

    init() {
        // Set events and such
        this.$el.on(EVENT.CLICK, () => {
            $document.triggerHandler({
                type: SHARE_MODAL_EVENT.SHOW
            })
        })
    }

    destroy() {
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
    }
}
