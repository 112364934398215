/* jshint esnext: true */
import { APP_NAME, $body, $document } from '../utils/environment';
import AbstractModule from './AbstractModule';
import { EVENT as CUSTOMIZER_EVENT } from './ProductCustomizer';

const MODULE_NAME = 'MaterialsPanel';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

export const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`,
    PICKER_CLICK: `picker_click.${EVENT_NAMESPACE}`,
    SELECT: `select.${EVENT_NAMESPACE}`,
    OPEN_PANEL: `open_panel.${EVENT_NAMESPACE}`,
    CLOSE_PANEL: `close_panel.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        this._targetPicker = null
        this.$list = this.$el.find('.c-product-settings_materials-panel_list')
        this.$materials = this.$el.find('.c-product-settings_materials-panel_item')

        // Declaration of properties
        console.log('🔨 ['+MODULE_NAME+']:constructor - Example');
    }

    init() {
        // Set events and such
        this.onPickerClick = (e) => {
            if(this._targetPicker != e.options.el) {
                this.setTargetPicker(e.options.el)
                this.openPanel()
            } else {
                this.togglePanel()
            }
        }
        $document.on(EVENT.PICKER_CLICK, this.onPickerClick)


        this.onMaterialClick = (e) => {
            let $el = $(e.currentTarget)

            this.$materials.toggleClass('-active', false)
            $el.toggleClass('-active', true)

            this._$targetPickerInput.val($el.data('slug')).change()
        }
        this.$materials.on(EVENT.CLICK, this.onMaterialClick)

        this.onCustomizerReset = () => {
            if(!this._targetPicker) return

            this.$materials.toggleClass('-active', false)
            this.$activeMaterial = $(Array.from(this.$availableMaterials).find(item => $(item).data('slug') == this._$targetPickerInput.val()))
            this.$activeMaterial.toggleClass('-active', true)
        }
        $document.on(CUSTOMIZER_EVENT.RESET, this.onCustomizerReset)

        $document.on(EVENT.OPEN_PANEL, this.openPanel.bind(this))
        $document.on(EVENT.CLOSE_PANEL, this.closePanel.bind(this))
    }

    togglePanel() {
        if($body.hasClass('customizer-materials-opened')) this.closePanel()
        else this.openPanel()
    }

    openPanel() {
        $body.toggleClass('customizer-materials-opened', true)
    }

    closePanel() {
        $body.toggleClass('customizer-materials-opened', false)
    }

    setTargetPicker(value) {
        // console.log('💁‍♂️ ['+MODULE_NAME+']:setTargetPicker - '+$(value).find('label').text());
        this._targetPicker = value
        this._$targetPicker = $(this._targetPicker)
        this._$targetPickerInput = this._$targetPicker.find('input')
        // this._$targetPickerTitle = this._$targetPicker.find('span')
        // this._$targetPickerSampleContent = this._$targetPicker.find('.o-material-sample_content')

        // Update list of available materials
        this.updateMaterials()
    }

    updateMaterials() {
        // Get available materials for this picker
        this.availableMaterialsList = $(this._targetPicker).data('available-materials').split(',')

        // Filter the materials list to get only the ones we need
        this.availableMaterials = Array.from(this.$materials).filter((item) => {
            return this.availableMaterialsList.includes($(item).data('slug'))
        })
        this.$availableMaterials = $(this.availableMaterials)

        // Get the to-be 'active' material according to picker value
        this.$activeMaterial = $(Array.from(this.$availableMaterials).find(item => $(item).data('slug') == this._$targetPickerInput.val()))

        // ###

        // Animation tween
        if(this.upMaterialsTl && this.upMaterialsTl.kill) this.upMaterialsTl.kill()
        this.upMaterialsTl = new TimelineMax()

        // OUT
        this.upMaterialsTl.set(this.$list, { opacity: 0 })
        // Actions to do between OUT/IN
        this.upMaterialsTl.addCallback(() => {
            // Filtering
            this.$materials.hide()
            this.$availableMaterials.show()

            // Set active
            this.$materials
            .toggleClass('-no-transition', true) // we don't want the `material-sample` active transition because it won't be visible and can cause lag
            .toggleClass('-active', false)
            this.$activeMaterial.toggleClass('-active', true)
            requestAnimationFrame(() => { this.$materials.toggleClass('-no-transition', false) }) // toggle back the `material-sample` active transition

            // Positioning
            TweenMax.set(this.$materials, { x: '-200%' })
        }, .15)
        // IN
        this.upMaterialsTl.set(this.$list, { opacity: 1 })
        this.upMaterialsTl.staggerTo(this.$availableMaterials, .5, { x: '0%', ease: CustomEase.get("EASE_COOL") }, .02)
    }

    destroy() {
        console.log('❌ ['+MODULE_NAME+']:destroy - Example');
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
    }
}
