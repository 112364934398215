/* jshint esnext: true */
import { APP_NAME, $document } from '../utils/environment';
import AbstractModule from './AbstractModule';
import { EVENT as TRANSITION_MANAGER_EVENT } from '../transitions/TransitionManager'

const MODULE_NAME = 'PortfolioFilters';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`,
    CHANGE: `change.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        // Declaration of properties
        // console.log('🔨 [module]:constructor - PortfolioFilters');
        this.$items = this.$el.find('.js-filters-item')
        this.$select = this.$el.find('.js-filters-select')
    }

    init() {
        // Set events and such
        this.$items.on(EVENT.CLICK, (e) => {
            this.$items.toggleClass('-active', false)
            $(e.currentTarget).toggleClass('-active', true)
        })

        this.$select.on(EVENT.CHANGE, (e) => {
            e.preventDefault()
            e.stopPropagation()

            $document.triggerHandler({
                type: TRANSITION_MANAGER_EVENT.GOTO,
                options: {
                    link: e.currentTarget.value,
                    transition: 'PortfolioTransition'
                }
            })
        })
    }

    destroy() {
        // console.log('❌ [module]:destroy - PortfolioFilters');
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
        this.$items.off(`.${EVENT_NAMESPACE}`);
        this.$select.off(`.${EVENT_NAMESPACE}`);
    }
}
