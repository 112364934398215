/* jshint esnext: true */
import { APP_NAME, $html, $document } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'Carousel';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

export const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`,
    OPEN: `open.${EVENT_NAMESPACE}`,
    CLOSE: `close.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        this.$slider = this.$el.find('.js-slider')

        this.$title = this.$el.find('.js-title')
        this.$desc = this.$el.find('.js-desc')

        this.$next = this.$el.find('.js-next')
        this.$prev = this.$el.find('.js-prev')
        this.$pager = this.$el.find('.js-pager')
        this.$close = this.$el.find('.js-close')
    }

    init() {
        // Set events and such
        this.swiper = new Swiper(this.$slider[0], {
            loop: true,
            slidesPerView: 'auto',
            grabCursor: true,
            navigation: {
                nextEl: this.$next[0],
                prevEl: this.$prev[0]
            },
            pagination: {
                el: this.$pager[0],
                type: 'custom',
                renderCustom: (swiper, current, total) => {
                    return `<span class="c-modal-carousel_pager_current">${current.toString().padStart(2, '0')}</span><span class="c-modal-carousel_pager_total">${total.toString().padStart(2, '0')}</span>`;
                }
            }
        })

        this.swiper.on('slideChange', () => {
            this.$title.text($(this.swiper.slides[this.swiper.activeIndex]).data('title'))
            this.$desc.text($(this.swiper.slides[this.swiper.activeIndex]).data('desc'))
        })

        $document.on(EVENT.OPEN, (e) => {
            // console.log(e.options);
            this.fill(e.options.slides)
            this.open(e.options.index)
        })
        $document.on(EVENT.CLOSE, (e) => { this.close() })
        this.$close.on(EVENT.CLICK, (e) => { this.close() })
    }

    open(index = 0) {
        $html.toggleClass('has-modal-carousel-opened', true)
        this.swiper.slideTo(index,0);
    }

    close() {
        $html.toggleClass('has-modal-carousel-opened', false)
        this.empty()
    }

    fill(items) {
        let slides = []
        for(let i = 0; i < items.length; i++) {
            const MARKUP = `<div class="c-modal-carousel_slide || swiper-slide" data-title="${items[i].title}" data-desc="${items[i].desc}">
                    <div class="c-modal-carousel_slide_img" style="background-image: url('${items[i].img}');">
                        <img src="${items[i].img}" alt="">
                    </div>
                </div>`

            slides.push(MARKUP)
        }

        this.swiper.appendSlide(slides)
        this.swiper.update()
    }

    empty() {
        this.swiper.removeAllSlides();
    }

    destroy() {
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
        $document.off(`.${EVENT_NAMESPACE}`);
    }
}
