import { APP_NAME, $document, $html, $body,  isDebug, $pjaxWrapper, $window } from '../utils/environment';

const MODULE_NAME = 'Transition';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`,
    READYTOREMOVE: `readyToRemove.${EVENT_NAMESPACE}`,
    READYTODESTROY: `readyToDestroy.${EVENT_NAMESPACE}`
};

export default class {
    constructor(options) {

        this.options = options;
        this.wrapper = options.wrapper;
        this.overrideClass = options.overrideClass ? options.overrideClass : '';
        this.clickedLink = options.clickedLink;

    }

    launch() {
        if(isDebug) {
            console.log("---- Launch transition 👊 -----");
        }

        $html
            .removeClass('dom-is-loaded dom-is-animated ')
            .addClass(`dom-is-loading ${this.overrideClass}`);

    }

    hideView(oldView, newView) {
        if(isDebug) {
            console.log('----- ❌ [VIEW]:hide - ', oldView.getAttribute('data-template'));
        }

        setTimeout(() => {
            // launch it at the end (animations...)
            $document.triggerHandler({
                type:EVENT.READYTOREMOVE,
                oldView: oldView,
                newView: newView
            });
        }, 500)
    }


    displayView(view) {

        if(isDebug) {
            console.log('----- ✅ [VIEW]:display :', view.getAttribute('data-template'));
        }

        $html.attr('data-template', view.getAttribute('data-template'));
        $html.attr('data-theme', view.getAttribute('data-theme'));

        let preloadPromises = [];

        $(view).find('.js-preload').each((i,item) => {
            preloadPromises.push(new Promise((resolve) => {
                let image = new Image();
                image.onload = resolve
                image.src = item.src;
            }));
        })

        Promise.all(preloadPromises).then(() => {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    $html
                        .addClass('dom-is-loaded')
                        .removeClass('dom-is-loading');

                    setTimeout(() => {
                        $html
                            .removeClass(this.overrideClass)
                            .addClass('dom-is-animated');

                        $window.trigger('resize')

                        // Make sure to resize if some images are coming in late
                        $(view).find('img').each((i,img) => {
                            img.onload = () => {
                                $window.trigger('resize')
                            }
                        })
                    }, 1000);

                    // launch it at the end (animations...)
                    $document.triggerHandler({
                        type:EVENT.READYTODESTROY
                    });
                });
            });
        })
    }


    destroy() {
        if(isDebug) {
            console.log("---- ❌ [transition]:destroy -----");
        }
    }
}
