/* jshint esnext: true */
import { $html } from './utils/environment';
import { detectIE } from './utils/is';
import TransitionManager from './transitions/TransitionManager';
import './utils/polyfill'
import svg4everybody from 'svg4everybody';


export default function(firstBlood) {
    svg4everybody();

    if (firstBlood) {
        const transitionManager = new TransitionManager();
    }

    let IE = detectIE()
    if(IE) {
        $html.toggleClass('is-ie', true)
        $html.toggleClass('is-ie-'+IE, true)
    }

    CustomEase.create("EASE_COOL", "M0,0 C0.446,0.442 0.052,0.988 1,1");
    TweenLite.defaultEase = Linear.easeNone;
    TweenMax.defaultEase = Linear.easeNone;
    TimelineLite.defaultEase = Linear.easeNone;
    TimelineMax.defaultEase = Linear.easeNone;
}
