/* jshint esnext: true */
import { APP_NAME } from '../utils/environment';
import AbstractModule from './AbstractModule';
import RailMove from './RailMove'

const MODULE_NAME = 'Rail';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options)

        this.$wrapper = this.$el
        this.speed = options['rail-speed']
    }

    init() {
        this.setClasses()
        this.wrapItem()

        this.initializeElements()
        this.fillScreen()
        this.groupTrack()
        this.duplicateGroup()
        this.wrapGroup()

        this.railMove = new RailMove({ $el: this.$wrapper, el: this.$wrapper[0] })
        this.railMove.init(this.$wrapper, this.speed)
        this.railMove.setContainerWidth(this.groupTrackBCR.width)
    }

    setClasses() {
        this.$wrapper.toggleClass('c-rail_wrapper')
        this.$wrapper.find('> div').toggleClass('c-rail_item')
    }

    wrapItem() {
        let html = this.$wrapper[0].innerHTML
        let newHtml = '<div class="c-rail_track">'+ html +'</div>'

        this.$wrapper[0].innerHTML = newHtml
    }

    initializeElements() {
        this.$item = this.$wrapper.find('.c-rail_item')
        this.$track = this.$wrapper.find('.c-rail_track')
        this.$container = this.$wrapper.find('.c-rail_container')
    }

    fillScreen() {
        let ratio = window.innerWidth / this.$track[0].getBoundingClientRect().width

        for (var i = 0; i < ratio; i++) {
            this.$wrapper.append(this.$track[0].outerHTML)
        }
    }

    groupTrack() {
        let html = this.$wrapper[0].innerHTML
        let newHtml = '<div class="c-rail_track-container">'+ html +'</div>'

        this.$wrapper[0].innerHTML = newHtml

        this.$groupTracks = this.$wrapper.find('.c-rail_track-container')
        this.groupTrackBCR = this.$groupTracks[0].getBoundingClientRect()
    }

    duplicateGroup() {
        this.$wrapper.append(this.$groupTracks[0].outerHTML)
    }

    wrapGroup() {
        let html = this.$wrapper[0].innerHTML
        let newHtml = '<div class="c-rail_group-container">'+ html +'</div>'

        this.$wrapper[0].innerHTML = newHtml

        this.$groupContainer = this.$wrapper.find('.c-rail_group-container')
    }

    destroy() {
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
        this.railMove.destroy()
    }
}
