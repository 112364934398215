/* jshint esnext: true */
import { APP_NAME, $document } from '../utils/environment';
import Modal, { EVENT as MODAL_EVENT } from './Modal';

import { EVENT as VIEWER_EVENT } from './Viewer';
import { DATABASE } from '../app'

const MODULE_NAME = 'ProductQuote';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

export const EVENT = MODAL_EVENT;

export default class extends Modal {
    constructor(options) {
        super(options);
        this.options = options

        // Declaration of properties
        console.log('🔨 ['+MODULE_NAME+']:constructor - Example');

    }

    init() {
        super.init()

        // Set events and such

        $document.on(VIEWER_EVENT.CHANGE, (e) => {
            let slug = e.options.slug
            let value = e.options.value

            let dbField = DATABASE.objects[this.options.productId][slug]

            // console.log(slug, value);

            let label
            if(dbField.type == "material-picker") {
                label = DATABASE.materials[value].label
            } else if(dbField.choices) {
                if(dbField.type == 'checkbox') {
                    let defaultIndex = Object.keys(dbField.choices).indexOf(dbField.default)
                    if(value !== true && value !== false) {
                        if(value == dbField.default) value = false
                        else value = true
                    }

                    if(value === false) {
                        label = dbField.choices[dbField.default].label
                    } else {
                        label = dbField.choices[Object.keys(dbField.choices)[defaultIndex == 1 ? 0 : 1]].label
                    }

                    // console.log(slug,label);
                } else {
                    label = dbField.choices[value].label
                }
            }

            this.$el.find('td[data-slug="'+slug+'"]').text(label)
        });

    }

    destroy() {
        console.log('❌ ['+MODULE_NAME+']:destroy - Example');
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
        $document.off(VIEWER_EVENT.CHANGE)
    }
}
