/* jshint esnext: true */
import { APP_NAME, $document } from '../utils/environment';
import AbstractModule from './AbstractModule';
import { EVENT as CAROUSEL_MODAL_EVENT } from './CarouselModal';

const MODULE_NAME = 'Carousel';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        this.name = options.name;

        this.$slider = this.$el.find('.js-slider')
        this.$next = this.$el.find('.js-next')
        this.$prev = this.$el.find('.js-prev')

        this.$slides = this.$el.find('.js-slide')
        this.nbSlides = this.$slides.length
    }

    init() {
        // Set events and such

        this.swiper = new Swiper(this.$slider[0], {
            loop: this.nbSlides > 1,
            grabCursor: true,
            spaceBetween: 40,
            threshold: 3,
            slidesPerView: 'auto',
            navigation: {
                nextEl: this.$next[0],
                prevEl: this.$prev[0]
            },
            breakpoints: {
                1000: {
                    spaceBetween: 15
                }
            }
        });

        if(this.nbSlides <= 1) {
            this.$next.hide()
            this.$prev.hide()
        }

        this.swiper.on('click', (event) => {
            if(window.innerWidth < 1000) return

            $document.triggerHandler({
                type: CAROUSEL_MODAL_EVENT.OPEN,
                options: {
                    index: this.swiper.activeIndex,
                    slides: Array.from(this.swiper.slides).map((el) => {
                        const $el = $(el)
                        if($el.hasClass('swiper-slide-duplicate')) return null

                        const title = $el.data('title')
                        const desc = $el.data('desc')
                        const img = $el.data('image')

                        return { title, desc, img }
                    }).filter(function (el) {
                      return el != null;
                    })
                }
            })
        })
    }

    destroy() {
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
    }
}
