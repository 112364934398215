/* jshint esnext: true */
import { APP_NAME, $document } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'Modal';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

export const EVENT = {
    OPEN: `open.${EVENT_NAMESPACE}`,
    CLOSE: `close.${EVENT_NAMESPACE}`,
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        // Declaration of properties
        console.log('🔨 ['+MODULE_NAME+']:constructor - Example');

        this.id = this.$el.data('id')

        this.$overlay = this.$el.find('.c-modal_overlay')
        this.$closeBtn = this.$el.find('.js-modal-close')
    }

    init() {
        // Set events and such
        $document.on(EVENT.OPEN, (e) => {
            if(e.options.id == this.id) {
                this.open()
            }
        })

        $document.on(EVENT.CLOSE, (e) => {
            if(e.options.id == this.id) {
                this.close()
            }
        })

        this.$closeBtn.on(EVENT.CLICK, (e) => {
            e.preventDefault();
            this.close()
        })

        this.$overlay.on(EVENT.CLICK, (e) => {
            if(e.target == e.currentTarget) this.close()
        })
    }

    open() {
        clearTimeout(this.timeoutClose)

        this.$el.toggleClass('is-open', true)
    }

    close() {
        this.$el.toggleClass('is-leaving', true)

        clearTimeout(this.timeoutClose)
        this.timeoutClose = setTimeout(() => {
            this.$el.toggleClass('is-open', false)
            this.$el.toggleClass('is-leaving', false)
        }, 1000);
    }

    destroy() {
        console.log('❌ ['+MODULE_NAME+']:destroy - Example');
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
        $document.off(`.${EVENT_NAMESPACE}`);
        this.$closeBtn.off(`.${EVENT_NAMESPACE}`);
    }
}
