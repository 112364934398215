/* jshint esnext: true */
import { APP_NAME, $body, $window, $document } from '../utils/environment';
import AbstractModule from './AbstractModule';

import { EVENT as MATERIALS_PANEL_EVENT } from './MaterialsPanel'

const MODULE_NAME = 'Tabs';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`,
    RESIZE: `resize.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        // Declaration of properties
        // console.log('🔨 ['+MODULE_NAME+']:constructor - Example');


        this.$containers = this.$el.find('.c-tabs_containers')
        this.$btns = this.$el.find('.c-tabs_btns')
        this.$btnsItem = this.$el.find('.c-tabs_btns_item')

        this.$btnsBorder = this.$el.find('.c-tabs_btns_border')
        this.$btnsHighlight = this.$el.find('.c-tabs_btns_border_highlight')
    }

    init() {
        // Set events and such

        // Init the slick
        this.$containers.slick({
            infinite: false,
            arrows: false
        })

        // Set the current tab manually
        this.$btnsItem.eq(this.$containers[0].slick.currentSlide).toggleClass('-active', true)

        // Listen for a change of tab
        this.onBeforeChange = (event, slick, currentSlide, nextSlide) => {
            if(currentSlide != nextSlide) $document.trigger(MATERIALS_PANEL_EVENT.CLOSE_PANEL);
            this.$btnsItem.toggleClass('-active', false)
            this.$btnsItem.eq(nextSlide).toggleClass('-active', true)
        }
        this.$containers.on('beforeChange', this.onBeforeChange)

        // Assign ids to each tab btn to help interact w/ slick
        this.$btnsItem.each((i,el) => {
            $(el).data('id', i)
        })

        // Actual btn click interaction
        this.$btnsItem.on(EVENT.CLICK, (e) => {
            this.$containers.slick('slickGoTo', $(e.currentTarget).data('id'))
        })

        // Setup the border tabs animation
        $window.on(EVENT.RESIZE, this.setBorderTween.bind(this))

        this.setBorderTween()
        this.updateBorder()
    }

    setBorderTween() {
        if(this.borderHighlightTl && this.borderHighlightTl.kill) this.borderHighlightTl.kill()

        this.borderHighlightTl = new TimelineMax({ repeat: -1 })

        let btnsCoords = []
        let borderBCR = this.$btnsBorder[0].getBoundingClientRect()
        this.$btnsItem.each((i,el) => {
            let elBCR = el.getBoundingClientRect()

            let width = elBCR.width / borderBCR.width
            let left = elBCR.left - borderBCR.left

            btnsCoords.push({ width, left })
        })

        for(let i = 0; i < btnsCoords.length - 1; i++) {
            this.borderHighlightTl.fromTo(this.$btnsHighlight, 1, { scaleX: btnsCoords[i].width, x: btnsCoords[i].left }, { scaleX: btnsCoords[i+1].width, x: btnsCoords[i+1].left })
        }

        this.borderHighlightTl.pause().progress(0.1).progress(0)
    }

    updateBorder() {
        let sliderBCR = this.$containers[0].slick.$slider[0].getBoundingClientRect()
        let trackBCR = this.$containers[0].slick.$slideTrack[0].getBoundingClientRect()

        let sliderPosition = sliderBCR.left - trackBCR.left

        let sliderWidth = (trackBCR.width / this.$containers[0].slick.slideCount) * (this.$containers[0].slick.slideCount - 1)
        let progress = sliderPosition / sliderWidth

        if(progress < 0) progress = 0
        else if(progress > 1) progress = 1

        this.borderHighlightTl.progress(progress)

        this._raf = requestAnimationFrame(this.updateBorder.bind(this))
    }

    destroy() {
        // console.log('❌ ['+MODULE_NAME+']:destroy - Example');
        super.destroy();

        cancelAnimationFrame(this._raf)

        this.$btnsItem.off(`.${EVENT_NAMESPACE}`);
        $window.off(`.${EVENT_NAMESPACE}`);
        this.$el.off(`.${EVENT_NAMESPACE}`);
    }
}
