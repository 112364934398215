/* jshint esnext: true */
import { APP_NAME, $document } from '../utils/environment';
import AbstractModule from './AbstractModule';

import { EVENT as APP_EVENT } from '../app.js'

const MODULE_NAME = 'ArticlesGrid';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        this.$list = this.$el.find('.js-articlesgrid-list')
        this.$loadmore = this.$el.find('.js-articlesgrid-loadmore')
        this.$loader = this.$el.find('.js-articlesgrid-loader')

        this.nextPage = 2;
    }

    init() {
        // Set events and such
        this.$loadmore.on('click', () => {
            this.$loadmore[0].style.display = 'none';
            this.$loader.toggleClass('-inactive', false)

            $.get(`/${window.lang}/fetch/news/list/${this.nextPage}`, (response) => {
                console.log(response);

                let $markup = $(response.html)

                $document.triggerHandler({
                    type: APP_EVENT.INIT_SCOPED_MODULES,
                    $scope: $markup
                });

                this.$loader.toggleClass('-inactive', true)
                this.$list.append($markup)
                if (response.nextPage !== null) {
                    this.nextPage = response.nextPage;
                    this.$loadmore[0].style.display = '';
                }
            });
        })
    }

    destroy() {
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
    }
}
