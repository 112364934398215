/* jshint esnext: true */
import { APP_NAME } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'ArticleThumb';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        this.$links = this.$el.find('.js-articlethumb-link')
    }

    init() {
        // Set events and such
        this.mouseEnterBind = () => {
            this.$el.toggleClass('is-hover', true)
        }
        this.$links.on('mouseenter', this.mouseEnterBind)

        this.mouseLeaveBind = () => {
            this.$el.toggleClass('is-hover', false)
        }
        this.$links.on('mouseleave', this.mouseLeaveBind)
    }

    destroy() {
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);

        this.$links.off('mouseenter', this.mouseEnterBind)
        this.$links.off('mouseleave', this.mouseLeaveBind)
    }
}
