/* jshint esnext: true */
import { APP_NAME } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'CarouselHeader';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);
    }

    init() {
       this.$el.slick({
            dots: false,
            infinite: true,
            speed: 600,
            autoplay: true,
            autoplaySpeed: 4000,
            pauseOnHover: false,
            pauseOnFocus: false,
            cssEase: 'cubic-bezier(0.365, 0.305, 0.000, 1)',
            prevArrow: '<button class="c-header-product_arrow -prev" type="button"><svg class="c-carousel_arrow_icon" role="img" title="Previous"><use xlink:href="assets/images/sprite.svg#arrow-prev"></use></svg></button>',
            nextArrow: '<button class="c-header-product_arrow -next" type="button"><svg class="c-carousel_arrow_icon" role="img" title="Next"><use xlink:href="assets/images/sprite.svg#arrow-next"></use></svg></button>'
       });

        this.$el.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
            if (nextSlide == 0) {
                const index = currentSlide + 1;
                this.$el.find(`[data-slick-index=${index}]`).addClass('is-active');
            } else if (currentSlide == 0 && nextSlide > 1) {
                const index = currentSlide - 1;
                this.$el.find(`[data-slick-index=${index}]`).addClass('is-active');
            }

            // RESET THE VISUAL TIMER IF RETURNING TO SAME SLIDE
            if(currentSlide == nextSlide) {
                requestAnimationFrame(() => {
                    this.$el.find(`[data-slick-index=${currentSlide}]`).toggleClass('slick-active', false)
                    requestAnimationFrame(() => {
                        this.$el.find(`[data-slick-index=${currentSlide}]`).toggleClass('slick-active', true)
                    });
                })
            }
        });

        this.$el.on('afterChange', (event, slick, currentSlide, nextSlide) => {
            // if (currentSlide == 0) {
                this.$el.find('.js-carousel-product-slide.is-active').removeClass('is-active');
            // }
        });
    }

    destroy() {
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
    }
}

