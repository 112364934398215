import { APP_NAME, $document } from '../utils/environment';
import AbstractModule from './AbstractModule';

import { Event as Scroll_Event } from './LocomotiveScroll';

const MODULE_NAME = 'Accordion';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        this.$scrollContainer = !$('html').hasClass('has-smooth-scroll') ? $document : $('.o-scroll');

        this.$el.on(EVENT.CLICK, '.js-accordion-header', (event) => {
            let $target = $(event.currentTarget);
            let $section = $target.parents('.js-accordion-section');

            if (!$section.hasClass('is-open')) {
                // Open
                this.open($target, $section);
            } else {
                // Close
                this.close($target, $section);
            }
        });
    }

    // Open
    // ==========================================================================
    open($target, $section) {
        this.closeAll();

        $target.siblings('.js-accordion-main').slideDown(300, () => this.scrollTo($target, $section));
        $section.addClass('is-open');
    }

    // Close
    // ==========================================================================
    close($target, $section) {
        $target.siblings('.js-accordion-main').slideUp(300, () => {
            this.$scrollContainer.triggerHandler({
                type: Scroll_Event.UPDATE
            })
        });
        $section.removeClass('is-open');
    }

    // Close all
    // ==========================================================================
    closeAll() {
        this.$el.find('.js-accordion-section.is-open')
            .removeClass('is-open')
            .find('.js-accordion-main')
            .slideUp(300);
    }

    // Scroll to
    // ==========================================================================
    scrollTo($target, $section) {
        this.$scrollContainer.triggerHandler({
            type: Scroll_Event.UPDATE
        })

        if (this.$el.data('accordion-scroll') !== false) {
            let offset = -100

            this.$scrollContainer.triggerHandler({
                type: Scroll_Event.SCROLLTO,
                options: {
                    targetElem: $section,
                    targetOffset: offset,
                    speed: 600
                }
            })
        }
    }

    // Destroy
    // ==========================================================================
    destroy() {
        this.$el.off(`.${EVENT_NAMESPACE}`);
    }
}
