/* jshint esnext: true */
import { APP_NAME, $document, $html } from '../utils/environment';
import AbstractModule from './AbstractModule';
import { Event as ScrollEvent }  from './LocomotiveScroll'

const MODULE_NAME = 'RailMove';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

export const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`,
    UPDATE_SCROLL: `updateScroll.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options)
    }

    init($el, speed) {
        this.$wrapper = $el

        this.containerWidth = 0
        this.requestAnimation = null
        this.scrollPosition = -1
        this.translation = 0

        this.grabbed = false
        this.preventClick = false

        this.originalVelocity = -speed
        this.velocity = this.originalVelocity

        this.initializeElements()
        this.initializeEvents()
    }

    initializeElements() {
        this.$railMove = this.$wrapper.find('.c-rail_group-container')
        this.getBCR()
    }

    initializeEvents() {
        this.updateBind = this.updateScroll.bind(this)
        this.update()
        this.updateBind()
        $document.on(EVENT.UPDATE_SCROLL, this.updateBind)
    }

    setContainerWidth(width) {
        this.containerWidth = width
    }

    getBCR() {
        this.railMoveBCR = this.$railMove[0].getBoundingClientRect()
    }

    updateScroll() {
        if ($html.hasClass('has-smooth-scroll')) {
            let newScrollPosition = window.scroll.y - this.scrollPosition;
            this.scrollPosition = window.scroll.y;

            if(newScrollPosition != 0)
               this.velocity = this.originalVelocity * newScrollPosition;
        }
    }

    update(){
        if(!this.grabbed) {
            if((this.translation > this.railMoveBCR.width / 2) || (this.translation < -this.railMoveBCR.width / 2)) {
                this.translation = 0
            } else {
                this.translation = this.translation + this.velocity
            }
        }

        let translation;
        if(this.translation > 0) {
            translation = -this.containerWidth + (this.translation % this.containerWidth)
        } else {
            translation = this.translation % this.containerWidth
        }

        TweenMax.set(this.$railMove, { x: translation, force3D: true })

        this.requestAnimation = window.requestAnimationFrame(this.update.bind(this))
    }

    destroy() {
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
        $document.off(EVENT.UPDATE_SCROLL, this.updateBind)
        window.cancelAnimationFrame(this.requestAnimation)
        TweenMax.set(this.$railMove, { x: 0 })
    }
}
