/* jshint esnext: true */
import { APP_NAME, $window } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'BambooHR';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);
    }

    init() {
        // Set events and such
        var embedUrl = "https://muraflex.bamboohr.com/jobs/embed2.php";
        var departmentId = "0";
        if (departmentId) {
            embedUrl += '?departmentId=' + encodeURIComponent(departmentId);
        }

        fetch(embedUrl).then(response => {
            return response.text()
        }).then(text => {
            this.el.innerHTML = text
            $window.trigger('resize')
        })
    }

    destroy() {
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
    }
}
