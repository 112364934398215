/* jshint esnext: true */
import { APP_NAME, $document } from '../utils/environment';
import AbstractModule from './AbstractModule';

import { lerp } from '../utils/maths'

const MODULE_NAME = 'Loader';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

export const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`,
    PROGRESS: `progress.${EVENT_NAMESPACE}`,
    COMPLETE: `complete.${EVENT_NAMESPACE}`,
    COMPLETED: `completed.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);
        this.options = options

        // Declaration of properties
        console.log('🔨 ['+MODULE_NAME+']:constructor - Example');

        this.$progress = this.$el.find('.js-loader-progress')

        this.progress = 0;
        this.target = 0;
    }

    init() {
        // Set events and such
        this.update()
        this.events()
    }

    events() {
        $document.on(EVENT.COMPLETE, (e) => {
            if(e.options.id == this.options.id) {
                this.complete()
            }
        })

        $document.on(EVENT.PROGRESS, (e) => {
            if(e.options.id == this.options.id && e.options.value) {
                this.target = e.options.value
            }
        })
    }

    update() {
        this.progress = lerp(this.progress, this.target, 0.02)

        TweenMax.set(this.$progress, { scaleX: this.progress })

        this.raf = requestAnimationFrame(this.update.bind(this))
    }

    complete() {
        cancelAnimationFrame(this.raf)

        return new Promise((resolve,reject) => {
            TweenMax.to(this.$progress, .2, { scaleX: 1, ease: Power2.easeOut, onComplete: () => {
                this.$el.toggleClass('-completed', true)

                $document.triggerHandler({
                    type: EVENT.COMPLETED,
                    options: {
                        id: this.options.id
                    }
                })
                resolve()
            } })
        })
    }

    destroy() {
        cancelAnimationFrame(this.raf)

        console.log('❌ ['+MODULE_NAME+']:destroy - Example');
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
    }
}
