/* jshint esnext: true */
import { APP_NAME, $document } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'Video';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        this.iframeSrc = options.iframe

        this.$overlay = this.$el.find('.js-video-overlay')
        this.$iframe = this.$el.find('.js-video-iframe')

        window.VideoPlayer = this
    }

    init() {
        this.loadYT().then(this.initYT.bind(this))

        // Set events and such
        this.overlayClickBind = () => {
            this.$el.toggleClass('-active', true)
            this.playVideo()

            this.$overlay.off(EVENT.CLICK, this.overlayClickBind)
        }
        this.$overlay.on(EVENT.CLICK, this.overlayClickBind)
    }

    loadYT() {
        return new Promise(resolve => {
            if (window.ytAPIReady) {
                resolve()
            } else {
                // Add youtube API
                let tag = document.createElement('script');
                tag.src = "https://www.youtube.com/iframe_api";
                let firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

                window.onYouTubeIframeAPIReady = () => {
                    window.ytAPIReady = true;
                    resolve()
                }
            }
        })
    }

    initYT() {
        return new Promise(resolve => {
            if(!this.playerReady) {
                this.player = new YT.Player(this.$iframe[0], {
                    playerVars: {
                        'rel': 0
                    },
                    events: {
                        'onReady': () => {
                            this.playerReady = true
                            resolve()
                        }
                    }
                })
            } else {
                resolve()
            }
        })
    }

    playVideo() {
        // Make sure load / init are done before trying to play, if not, wait for them
        this.loadYT().then(this.initYT.bind(this)).then(() => {
            this.player.playVideo()
        })
    }

    destroy() {
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);

        this.player.destroy();
        this.player = null;
    }
}
