/* jshint esnext: true */
import { APP_NAME, $window } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'ContactForm';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`,
    CHANGE: `change.${EVENT_NAMESPACE}`,
    SUBMIT: `submit.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        // Declaration of properties
        // console.log('🔨 [module]:constructor - ContactForm');

        this.$amDummy = this.$el.find('.js-am-dummy');
        this.$wantDummy = this.$el.find('.js-want-dummy');
        this.$productDummy = this.$el.find('.js-product-dummy');

        this.$am = this.$el.find('.js-am');
        this.$want = this.$el.find('.js-want');
        this.$product = this.$el.find('.js-product');

        this.$fullForm = this.$el.find('.js-full');
        this.$brochureForm = this.$el.find('.js-brochure');

        this.$forms = this.$el.find('form');
    }

    init() {
        let params = this.queryParams();
        if (params.hasOwnProperty('want')) {
            this.$wantDummy.val(params.want);
        }

        if (params.hasOwnProperty('product')) {
            this.$productDummy.val(params.product);
        }

        // Set events and such
        this.switchView();
        this.syncForms();

        this.$amDummy.on(EVENT.CHANGE, () => {
            this.syncForms();
        });

        this.$wantDummy.on(EVENT.CHANGE, () => {
            this.switchView();
            this.syncForms();
        });

        this.$productDummy.on(EVENT.CHANGE, () => {
            this.syncForms();
        });
    }

    queryParams() {
        var pairs = location.search.slice(1).split('&');

        var result = {};
        pairs.forEach(function (pair) {
            pair = pair.split('=');
            if (pair[1]) {
                result[pair[0]] = decodeURIComponent(pair[1] || '');
            }
        });

        return JSON.parse(JSON.stringify(result));
    };

    switchView() {
        if(this.$wantDummy[0].value === 'brochure') {
            this.$productDummy.find('.js-general').attr('disabled', true);
            if (this.$productDummy.val() === null) {
                this.$productDummy.val(this.$productDummy.children().first().val());
            }

            this.$fullForm.toggleClass('is-hidden', true);
            this.$brochureForm.toggleClass('is-hidden', false);
        } else {
            this.$productDummy.find('.js-general').attr('disabled', false);
            this.$fullForm.toggleClass('is-hidden', false);
            this.$brochureForm.toggleClass('is-hidden', true);
        }

        $window.trigger('resize');
    }

    syncForms() {
        this.$am.val(this.$amDummy.val());
        this.$want.val(this.$wantDummy.val());
        this.$product.val(this.$productDummy.val());
    }

    destroy() {
        // console.log('❌ [module]:destroy - ContactForm');
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
    }
}
