/* jshint esnext: true */
import {$document, APP_NAME} from '../utils/environment';
import AbstractModule from './AbstractModule';
import {EVENT as TRANSITION_MANAGER_EVENT} from '../transitions/TransitionManager'
import {Event as Scroll_Event} from './LocomotiveScroll'

const MODULE_NAME = 'NewsPager';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`,
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        this.$scrollContainer = !$('html').hasClass('has-smooth-scroll') ? $document : $('.o-scroll');
        this.scrollTarget = this.$el.data('scroll-target');

        // Declaration of properties
        console.log(MODULE_NAME);
    }

    init() {
        this.$el.on(EVENT.CLICK, 'a', (e) => {
            e.preventDefault()
            e.stopPropagation()

            this.$scrollContainer.triggerHandler({
                type: Scroll_Event.SCROLLTO,
                options: {
                    targetElem: $(this.scrollTarget).first()
                }
            });

            $document.triggerHandler({
                type: TRANSITION_MANAGER_EVENT.GOTO,
                options: {
                    link: e.currentTarget.href,
                    transition: 'NewsListTransition'
                }
            })
        })
    }

    destroy() {
        // console.log('❌ [module]:destroy - Example');
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
    }
}
