/* jshint esnext: true */
import { APP_NAME, $document } from '../utils/environment';
import AbstractModule from './AbstractModule';
import { EVENT as TRANSITION_MANAGER_EVENT } from '../transitions/TransitionManager'

const MODULE_NAME = 'NewsFilters';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`,
    CHANGE: `change.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        console.log(MODULE_NAME);
    }

    init() {
        this.$el.on(EVENT.CHANGE, (e) => {
            e.preventDefault()
            e.stopPropagation()

            $document.triggerHandler({
                type: TRANSITION_MANAGER_EVENT.GOTO,
                options: {
                    link: window.location.pathname+'?category='+e.currentTarget.value,
                    transition: 'NewsListTransition'
                }
            })
        })
    }

    destroy() {
        // console.log('❌ [module]:destroy - PortfolioFilters');
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
    }
}
