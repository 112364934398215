/* jshint esnext: true */
import { APP_NAME, $html, $document } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'ShareModal';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

export const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`,
    SHOW: `show.${EVENT_NAMESPACE}`,
    CLOSE: `close.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        this.$close = this.$el.find('.js-close')
        this.$shareBtns = this.$el.find('.js-share')
        this.$copy = this.$el.find('.js-copy')
    }

    init() {
        // Manage Open / close
        $document.on(EVENT.SHOW, (e) => {
            this.show()
        })
        $document.on(EVENT.CLOSE, (e) => { this.hide() })
        this.$close.on(EVENT.CLICK, (e) => { this.hide() })

        // Manage sharing
        this.$shareBtns.on(EVENT.CLICK, this.share.bind(this))

        // Manage clipboard
        this.clipboard = new ClipboardJS(this.$copy[0], {
            text: function(trigger) {
                return window.location.href
            }
        });

        this.clipboard.on('success', () => {
            alert(this.$copy[0].getAttribute('data-text'))
        })
    }

    show() {
        $html.toggleClass('has-modal-share-opened', true)
    }

    hide() {
        $html.toggleClass('has-modal-share-opened', false)
    }

    share(e) {
        const el = e.currentTarget;
        const platform = el.getAttribute('data-platform')
        const url = window.location.href
        let shareUrl;

        switch(platform) {

            case 'facebook':
                shareUrl = 'https://facebook.com/sharer/sharer.php?u=' + url;
                this.openWindow(shareUrl);
                break;

            case 'twitter':
                shareUrl = 'https://twitter.com/share?url=' + url
                         + '&amp;text=' + encodeURIComponent(el.getAttribute('data-text'))
                this.openWindow(shareUrl);
                break;

            case 'mail':
                const subject = encodeURIComponent(el.getAttribute('data-subject'))
                const body = encodeURIComponent(el.getAttribute('data-body'))
                this.openMail(subject, body);
                break;

            case 'copylink':
                el.setAttribute('data-clipboard-text', url)
                console.log('copylink', el.getAttribute('data-clipboard-text'));

                let clipboard = new ClipboardJS(el);
                clipboard.on('error', function(e) {
                    console.error('Action:', e.action);
                    console.error('Trigger:', e.trigger);
                    clipboard.destroy();
                });
                clipboard.on('success', () => {
                    alert(el.getAttribute('data-text'))
                    clipboard.destroy();
                });
                break;
        }
    }

    openWindow(url) {
        window.open(url, 'Share', 'menubar=no, toolbar=no, resizable=yes, scrollbars=yes, height=500, width=600');
    }

    openMail(subject, body) {
        window.location = 'mailto:?subject=' + subject + '&body=' + body;
    }

    destroy() {
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
        this.clipboard.destroy()
    }
}
