/* jshint esnext: true */
import { APP_NAME, $html, $document } from '../utils/environment';
import AbstractModule from './AbstractModule';
import { EVENT as TRANSITION_MANAGER_EVENT } from '../transitions/TransitionManager'

const MODULE_NAME = 'Header';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

export const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`,
    TOGGLE: `toggle.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);
    }

    init() {
        this.$el.on('click', '.js-header-navbutton', (event) => this.toggleNav());

        this.onRequestSent = () => {
            if($html.hasClass('has-nav-open'))
                this.toggleNav(false)
        }
        $document.on(TRANSITION_MANAGER_EVENT.REQUEST_SENT, this.onRequestSent)
    }

    toggleNav(value) {
        $html.toggleClass('has-nav-open', value);
        $document.trigger(EVENT.TOGGLE)
    }

    destroy() {
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
    }
}
