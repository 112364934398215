/* jshint esnext: true */
import { APP_NAME, $document } from '../utils/environment';

let initialising = false;
let initialized = false;

/**
 *Init google map only once and call a callback method when finished.
 * @param   {object}  options
 */
export function initGmap(cb) {
    if (initialized) {
        return cb();
    }

    $document.on('google_map_init', () => cb());

    if (initialising) {
        return;
    }

    initialising = true;

    window._tmp_google_onload = () => {
        initialized = true;
        $document.triggerHandler({
            type: 'google_map_init'
        })
    };

    $.getScript(
        'https://maps.googleapis.com/maps/api/js?language=en&callback=_tmp_google_onload&key=' + window.google_console,
        function() {}
    );
}
