/* jshint esnext: true */
import { APP_NAME, $document, $window } from '../utils/environment';
import AbstractModule from './AbstractModule';
import LocomotiveScroll from './LocomotiveScrollManager';
import { EVENT as HEADER_EVENT } from './Header'

const MODULE_NAME = 'Nav';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`,
    RESIZE: `resize.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        this.smoothActive = false;

        this.$emptyMargin = this.$el.find('.js-empty-margin')
        this.$list = this.$el.find('.js-list')
        this.$nextWrapper = this.$el.find('.js-next-wrapper')
        this.$nextBtn = this.$el.find('.js-next-btn')

        this.$filters = this.$el.find('.js-filters')
        this.$categories = this.$el.find('.js-category');
        this.$products = this.$el.find('.js-product')

        this.products = Array.from(this.$products).map((item) => {
            return {
                el: item,
                $el: $(item),
                categories: $(item).data('categories').split(',')
            }
        })

        this.hideTimeout = null
        this.productsTimeouts = []
    }

    init() {
        // ######
        // WE DONT WANT THE CAROUSEL ON MOBILE
        this.onResize = () => {
            if(window.innerWidth > 1000) {
                if(!this.smoothActive)
                    this.initSmooth()
            } else {
                if(this.smoothActive)
                    this.removeSmooth()
            }
        }
        $window.on(EVENT.RESIZE, this.onResize)
        this.onResize() // Manual call for first hit

        this.$nextBtn.on(EVENT.CLICK, () => {
            if(this.scroll && this.scroll.instance)
                if(this.$nextBtn.hasClass('-reversed')) this.scroll.instance.scrollTo({ targetOffsetHorizontal: 0 })
                else this.scroll.instance.scrollTo({ targetOffsetHorizontal: this.scroll.instance.scroll.x+window.innerWidth/2 })
            else {
                const UNIT = Math.ceil(this.$products[0].getBoundingClientRect(0).width)

                let diff = UNIT
                if(this.$list[0].scrollLeft != 0)
                    diff = (UNIT % this.$list[0].scrollLeft)
                if(diff == 0) diff = UNIT

                let destValue = this.$list[0].scrollLeft+diff

                if(this.$nextBtn.hasClass('-reversed')) destValue = 0
                TweenMax.to(this.$list[0], .5, { scrollLeft: destValue, ease: Power2.easeInOut })
            }
        })

        this.$categories.on(EVENT.CLICK, (e) => {
            let $el = $(e.currentTarget);
            let category = $el.data('category');
            let products;

            // Clear all timeouts
            clearTimeout(this.hideTimeout)
            for(let timeout of this.productsTimeouts) {
                clearTimeout(timeout);
            }
            this.productsTimeouts = []

            // Update filter buttons
            this.$categories.toggleClass('-active', false)
            $el.toggleClass('-active', true)

            if(this.scroll && this.scroll.instance)
                this.scroll.instance.scrollTo({ toTop: true })
            else
                this.$list[0].scrollTo(0,0)

            // Get the products targeted
            if(category === 'all') {
                products = this.products;
            } else {
                products = this.products.filter((item) => {
                    return item.categories.includes(category)
                })
            }

            // Hide them all
            this.$products.toggleClass('-visible', false)

            // Wait for the animation to end
            this.hideTimeout = setTimeout(() => {
                // Display none all
                this.$products.hide()

                // Display only the ones we need to prevent layout offset
                for(let product of products) {
                    product.$el.show()
                }

                // Wait a frame for the transition to be ready
                requestAnimationFrame(() => {
                    let i = 0;

                    // Trigger classes with delay
                    for(let product of products) {
                        this.productsTimeouts.push(setTimeout(() => {
                            product.$el.toggleClass('-no-delay', true).toggleClass('-visible', true);
                        }, i*100));
                        i++;
                    }

                    this.productsTimeouts.push(setTimeout(() => {
                        this.$products.toggleClass('-no-delay', false)
                    },i*100+300));
                })
            }, 300);
        })

        $document.on(HEADER_EVENT.TOGGLE, () => {
            if(this.scroll && this.scroll.instance)
                this.scroll.instance.scrollTo({ targetOffsetHorizontal: 0 })
            else {
                this.$list[0].scrollLeft = 0
            }
        })
    }

    initSmooth() {
        this.smoothActive = true

        this.scroll = new LocomotiveScroll({
            container: this.$el, //.find('.js-scroll'),
            mobileContainer: this.$el,
            selector: '.js-animate',
            smooth: true,
            smoothMobile: true,
            reversed: true,
            main: false,
            onScroll: (scroll, $container) => {
                if(window.innerWidth < 1000) {
                    TweenMax.set(this.$filters, { clearProps: 'all' })
                    return;
                }

                const OFFSET = this.$emptyMargin[0].getBoundingClientRect().width;

                TweenMax.set(this.$filters, { clearProps: 'all' })
                let value = scroll.x + this.$filters[0].getBoundingClientRect().left;


                if(value - OFFSET < scroll.x)
                    TweenMax.set(this.$filters, { x: scroll.x - value + OFFSET, force3D: true })

                TweenMax.set(this.$nextWrapper, { x: scroll.x, force3D: true });

                if(this.scroll && this.scroll.instance) {
                    this.$nextBtn.toggleClass('-reversed', scroll.x >= this.scroll.instance.scrollbar.limit.x - 200)
                }
            }
        });
    }

    removeSmooth() {
        this.smoothActive = false
        this.scroll.destroy()
        this.scroll = null
    }

    destroy() {
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
        this.$nextBtn.off(`.${EVENT_NAMESPACE}`);
        this.$categories.off(`.${EVENT_NAMESPACE}`);
    }
}
